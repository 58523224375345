import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[fallbackSrc]',
})
export class FallbackImageDirective {
  @Input() fallbackSrc: string;

  constructor(private ref: ElementRef<HTMLImageElement>) {}

  @HostListener('error')
  loadFallbackImage() {
    this.ref.nativeElement.src = this.fallbackSrc;
  }
}
