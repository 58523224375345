import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
})
export class KebabCasePipe implements PipeTransform {
  transform(val) {
    return val.replace(/\s/g, '-').replace("'", '-').replace(',', '').toLowerCase();
  }
}
