import { NgModule } from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { SafePipe } from './safe.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { KebabCasePipe } from './kebab-case.pipe';
import { StripHtmlTagsPipe } from './strip-html-tags.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { CopyArrayPipe } from './copy-array.pipe';
import { UtcToLocalDatePipe } from './utc-to-local-date.pipe';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { SanitizeHtmlPipe } from '@app/shared/sanitizer.pipe';
import { FallbackImageDirective } from '@app/shared/fallback-image.directive';

@NgModule({
  imports: [],
  declarations: [
    KebabCasePipe,
    EllipsisPipe,
    KeysPipe,
    SafePipe,
    StripHtmlTagsPipe,
    SentenceCasePipe,
    CopyArrayPipe,
    UtcToLocalDatePipe,
    BlockCopyPasteDirective,
    SanitizeHtmlPipe,
    FallbackImageDirective,
  ],
  exports: [
    KebabCasePipe,
    EllipsisPipe,
    KeysPipe,
    SafePipe,
    StripHtmlTagsPipe,
    SentenceCasePipe,
    CopyArrayPipe,
    UtcToLocalDatePipe,
    BlockCopyPasteDirective,
    SanitizeHtmlPipe,
    FallbackImageDirective,
  ],
})
export class SharedModule {}
