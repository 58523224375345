import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocal',
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(utcDate: string): string {
    const localDate = new Date(utcDate);
    localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
    return localDate.toString();
  }
}
